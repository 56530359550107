import Image from 'next/image';

import { SusnetLink } from '~/components/_wrapper/SusnetLink';

interface Banner {
  src?: string;
  alt: string;
  link?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

interface MainBannersProps {
  banners: Banner[];
}

const MainBanners: React.FC<MainBannersProps> = ({ banners }) => {
  return (
    <div className='flex flex-col gap-l lg:flex-row'>
      {banners.map((banner, index) => (
        <SusnetLink
          href={banner.link || ''}
          key={index}
          className='relative h-[180px] min-h-[180px] flex-1'
          target={banner.target}
        >
          {banner.src ? (
            <Image
              src={banner.src}
              alt={banner.alt}
              layout='fill'
              objectFit='contain'
              className='rounded-corner-l'
            />
          ) : (
            <div className='flex h-[180px] items-center justify-center rounded-corner-l bg-gray-10'>
              {banner.alt}
            </div>
          )}
        </SusnetLink>
      ))}
    </div>
  );
};

export default MainBanners;
